import React, { Suspense } from 'react'
import RequireUser from './contexts/RequireUser'; 
import FullScreenLoader from "./components/smallcomp/FullScreeenLoader"
import { registerLicense } from '@syncfusion/ej2-base';


import { Route, Routes } from 'react-router-dom';
import Login from "./pages/Dashboard/Login"
import NotFoundPage from "./components/NotFoundPage"

const Dashboard = React.lazy(() => import('./pages/Dashboard/Dashboard'));
const Common = React.lazy(() => import('./components/Common'));
const Indicator = React.lazy(() => import('./pages/Dashboard/Indicator'));
const City = React.lazy(() => import('./pages/Dashboard/City'));
const ProjectPage = React.lazy(() => import('./pages/Dashboard/ProjectPage'));
const CompanyDetail = React.lazy(() => import('./pages/Dashboard/Company/CompanyDetail'));
const Settings = React.lazy(() => import('./pages/Dashboard/Settings.jsx'));
const Robots = React.lazy(() => import('./pages/Dashboard/Robots/Robots.jsx'));
const RobotsMain = React.lazy(() => import('./pages/Dashboard/Robots/RobotsMain.jsx'));
const AllCases = React.lazy(() => import('./pages/Dashboard/Case/AllCases.jsx'));
const CasePage = React.lazy(() => import('./pages/Dashboard/Case/CasePage.jsx'));
const Cameras = React.lazy(() => import('./pages/Dashboard/Cameras/Cameras.jsx'));
const Player = React.lazy(() => import('./pages/Dashboard/Cameras/Player.jsx'));
const MainTables = React.lazy(() => import('./pages/Dashboard/Tables/MainTables.jsx'));
const TablePage = React.lazy(() => import('./pages/Dashboard/Tables/TablePage.jsx'));
const AllReports = React.lazy(() => import('./pages/Dashboard/Reports/AllReports.jsx'));
const EditReport = React.lazy(() => import('./pages/Dashboard/Reports/EditReport.jsx'));
const ReportsPage = React.lazy(() => import('./pages/Dashboard/Reports/ReportsPage.jsx'));
const Journal = React.lazy(() => import('./pages/Dashboard/Journal/Journal.jsx'));
const CityMap = React.lazy(() => import('./pages/Dashboard/CityMap.jsx'));
const HomePage = React.lazy(() => import('./pages/Dashboard/HomePage.jsx'));
const PersonalDash = React.lazy(() => import('./pages/PersonalDash/PersonalDash.jsx'));
const AllInstruments = React.lazy(() => import('./pages/Dashboard/Instruments/AllInstruments.jsx'));
const InstrumentsPage = React.lazy(() => import('./pages/Dashboard/Instruments/InstrumentPage.jsx'));
const Employee = React.lazy(() => import('./pages/Dashboard/Employee/Employee.jsx'));
const EmployeeProfilPage = React.lazy(() => import('./pages/Dashboard/Employee/EmployeeProfilPage.jsx'));
const Tabeldepontaj = React.lazy(() => import('./pages/Dashboard/Employee/EmployeeComp/Tabeldepontaj.jsx'));
const EmployeeProjectList = React.lazy(() => import('./pages/Dashboard/Employee/EmployeeProjects/EmployeeProjectList.jsx'));
const TaskPage = React.lazy(() => import('./pages/Projects/TaskPage/TaskPage.jsx'));
const Smeta = React.lazy(() => import('./pages/Projects/Project/Smeta.jsx'));
const ProjectPlan = React.lazy(() => import('./pages/Projects/Project/ProjectPlan.jsx'));
const Expense = React.lazy(() => import('./pages/Dashboard/Expense/Expense.jsx'));
const SuplierPage = React.lazy(() => import('./pages/Dashboard/Expense/Supliers/SuplierPage.jsx'));
const EmployeeSalary = React.lazy(() => import('./pages/Dashboard/Employee/EmployeeSalary.jsx'));
const ProjectLucratoriManager = React.lazy(() => import('./pages/Projects/ProrabView/Lucratori/ProiectLucratoriManager.jsx'));
const EmploeeToManager = React.lazy(() => import('./pages/Dashboard/Employee/EmploeeToManager.jsx'));
const GlobalMap = React.lazy(() => import('./pages/Dashboard/Map/globalmap.jsx'));
const Cheltuieli = React.lazy(() => import('./pages/Projects/ProrabView/Cheltuieli/Cheltuieli.jsx'));
const ReceptieMateriale = React.lazy(() => import('./pages/Projects/ProrabView/ReceptieMateriale/ReceptieMateriale.jsx'));
const ImplementareMateriale = React.lazy(() => import('./pages/Projects/ProrabView/ImplementareMateriale/ImplementareMateriale.jsx'));
const Incasari = React.lazy(() => import('./pages/Projects/ProrabView/Incasari/Incasari.jsx'));
const Combustibil = React.lazy(() => import('./pages/Projects/ProrabView/Combustibil/Combustibil.jsx'));
const ReceptieFacturi = React.lazy(() => import('./pages/Projects/ProrabView/ReceptieFacturi/ReceptieFacturi.jsx'));
registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE);


const App = () => {

  return (
    <div>
      <Suspense fallback={<FullScreenLoader />}>
      <Routes>
        <Route>
        <Route path="/404error" element={<NotFoundPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<HomePage />} />
          <Route element={<RequireUser allowedRoles={[1, 2, 3, 4, 5]} />}>          
            <Route path='/' element={<Common />}>
              <Route path="personaldash" element={<PersonalDash />} />              
              <Route path="personaldash/:dash_id" element={<PersonalDash />} />      
              <Route path="/dash" element={<Dashboard />} />
              <Route path="/dash*" element={<Dashboard />} />
              <Route path="oras/:city/:city_id" element={<CityMap />} />
              <Route path="projetct/:project_name/:project_id" element={<ProjectPage />} />
              <Route path="oras/:city/:city_id/:project_name/:project_id" element={<ProjectPage />} />
              <Route path="projects/:project_id" element={<ProjectPage />} />
              <Route path="oras/:city/:city_id/:project_name/:project_id/:tab" element={<ProjectPage />} />
              <Route path="oras/:city/:city_id/:project_name/:project_id/:indicator_name/:indicator_id" element={<Indicator />} />
              <Route path="oras/:city/:city_id/:project_name/:project_id/:indicator_name/:indicator_id/:tab" element={<Indicator />} />
              <Route path="cases" element={<AllCases />} />
              <Route path="cases/:tab" element={<AllCases />} />
              <Route path="case/:case_id" element={<CasePage />} />
              <Route path="oras/:city/:city_id/:project_name/:project_id/case/:case_id" element={<CasePage />} />
              <Route path="company/:company_id" element={<CompanyDetail />} />
              <Route path="employee/:employee_id" element={<EmployeeProfilPage />} />
              <Route path="employee/:employee_id/:tab" element={<EmployeeProfilPage />} />
              <Route path="oras/:city/:city_id/:project_name/:project_id/:tab" element={<EmployeeProjectList />} />

              {/* Manager View */}
              <Route path="oras/:city/:city_id/:project_name/:project_id/lucratori_manager" element={<ProjectLucratoriManager />} />
              <Route path="oras/:city/:city_id/:project_name/:project_id/cheltuieli" element={<Cheltuieli />} />
              <Route path="oras/:city/:city_id/:project_name/:project_id/receptie_materiale" element={<ReceptieMateriale />} />
              <Route path="oras/:city/:city_id/:project_name/:project_id/implementare_materiale" element={<ImplementareMateriale />} />
              <Route path="oras/:city/:city_id/:project_name/:project_id/incasari" element={<Incasari />} />
              <Route path="oras/:city/:city_id/:project_name/:project_id/combustibil" element={<Combustibil />} />
              <Route path="oras/:city/:city_id/:project_name/:project_id/receptie_facturi" element={<ReceptieFacturi />} />


              
              <Route element={<RequireUser allowedRoles={[1, 2]} />}>
                <Route path="instruments" element={<AllInstruments />} />

                <Route path="instruments/:tab" element={<AllInstruments />} />
                <Route path="instruments/id/:instrument_id" element={<InstrumentsPage />} />
                <Route path="employee" element={<Employee />} />
                <Route path="employee/tabeldepontaj" element={<Tabeldepontaj />} />
                <Route path="employee/salary" element={<EmployeeSalary />} />
                <Route path="employee/manager" element={<EmploeeToManager />} />
                <Route path="journal/" element={<Journal />} />
                <Route path="reports/" element={<AllReports />} />
                <Route path="reports/edit/:report_id" element={<EditReport />} />
                <Route path="reports/:report_id/" element={<ReportsPage />} />   
                <Route path="smeta/:project_id" element={<Smeta />} />
                <Route path="projectplan/:project_id" element={<ProjectPlan />} />
                <Route path="settings/" element={<Settings />} />
                <Route path="settings/:tab" element={<Settings />} />
                <Route path="robots/:robot_id" element={<Robots />} />
                <Route path="robots" element={<RobotsMain />} />
                <Route path="table" element={<MainTables />} />
                <Route path="table/:table_id" element={<TablePage />} />
                <Route path="cameras/:camera_id" element={<Player />} />
                <Route path="cameras" element={<Cameras />} />
                <Route path="projects/:project_id/task/:task_id" element={<TaskPage />} />
                <Route path="oras/:city/:city_id/:project_name/:project_id/task/:task_id" element={<TaskPage />} />
                <Route path="expense" element={<Expense />} />
                <Route path="expense/:tab" element={<Expense />} />
                <Route path="expense/:tab/:subtab" element={<Expense />} />
                <Route path="expense/supplier/:supplier_id" element={<SuplierPage />} />
                <Route path="expense/supplier/:supplier_id/:tab" element={<SuplierPage />} />
                <Route path="map" element={<GlobalMap />} />
              </Route>
            </Route>
        </Route>

        </Route>
        
      </Routes>
      </Suspense>
    </div>
  );
}

export default App;